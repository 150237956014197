import { useCallback, useEffect, useState } from 'react';
import { useTrophyClient } from '../clients/trophy-client/trophy-client';
import { Trophy } from '../model/trophy/trophy';

export const useTrophies = (standorte: string, selectedSaison: string) => {
    const [trophies, setTrophies] = useState<Trophy[]>([]);
    const { loadTrophies } = useTrophyClient();

    const load = useCallback(() => {
        loadTrophies(standorte, selectedSaison)
            .then((trophies) => {
                setTrophies(trophies);
            })
            .catch(() => {});
    }, [loadTrophies, selectedSaison, standorte]);

    useEffect(() => {
        load();
    }, [load]);

    return { trophies };
};
