import { GameDto } from '../../model/game.dto';
import { gameRankingFromDto } from '../../model/ranking/game-ranking';
import { Ranking } from '../../model/ranking/ranking';
import { RankingTypeDto } from '../../model/ranking/ranking-type.dto';
import { useHttpClient } from '../http-client/http-client';
import { useCallback } from 'react';

export interface RankingDto {
    digits: number;
    gameRankings: GameRankingDto[];
}

export interface GameRankingDto {
    game: GameDto;
    playerRankings: PlayerRankingDto[];
}

export interface PlayerRankingDto {
    playerId: number;
    rankingValue: number;
}

export const useRankingClient = () => {
    const { get } = useHttpClient();

    const loadRanking = useCallback(
        async (
            url: string,
            showOnlyLastGames: boolean,
            standorte: string,
            selectedSaison: string,
            limit: number = -1
        ): Promise<Ranking> => {
            let requestUrl = `/api/ranking/${url}`;
            const params = [];
            if (showOnlyLastGames) {
                params.push(`onlyLast=true`);
            }
            if (standorte !== 'ALL') {
                params.push(`standorte=${standorte}`);
            }
            if (limit !== -1) {
                params.push(`limit=${limit}`);
            }
            if (selectedSaison !== 'ALL_TIME') {
                params.push(`saison=${selectedSaison}`);
            }
            if (params.length > 0) {
                requestUrl += '?';
                requestUrl += params.join('&');
            }
            const response = await get<RankingDto>(requestUrl);
            return new Ranking(
                response.digits,
                response.gameRankings.map((gameRankingDto) => gameRankingFromDto(gameRankingDto))
            );
        },
        [get]
    );

    const loadRankingTypes = useCallback((): Promise<RankingTypeDto[]> => {
        return get<RankingTypeDto[]>('/api/ranking');
    }, [get]);

    return { loadRanking, loadRankingTypes };
};
