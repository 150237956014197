import { Checkbox, Skeleton, TableCell, TableRow } from '@mui/material';
import React from 'react';
import { PlayerDto } from '../../model/player.dto';
import './ranking-table-row.css';
import { getPlayerNameWithTrophy } from './get-player-name-with-trophy';
import { Trophy } from '../../model/trophy/trophy';

export type RowData = {
    isPlayerShown: boolean;
    hidePlayer: (player: PlayerDto) => void;
    showPlayer: (player: PlayerDto) => void;
    highlightPlayer: (player: PlayerDto) => void;
    color: string;
    rank: number;
    image: JSX.Element;
    player: PlayerDto;
    rankingValue: string;
    movePositionIcon: JSX.Element;
    diffElement: JSX.Element;
    trophies: Trophy[];
};

export const RankingTableRow = (props: { rowData?: RowData }) => {
    const clickHandlers = props.rowData
        ? {
              onClick: () =>
                  props.rowData.isPlayerShown
                      ? props.rowData.hidePlayer(props.rowData.player)
                      : props.rowData.showPlayer(props.rowData.player),
              onDoubleClick: () => props.rowData.highlightPlayer(props.rowData.player),
          }
        : {};
    return (
        <TableRow
            style={{ cursor: props.rowData ? 'pointer' : 'initial' }}
            {...clickHandlers}
        >
            <TableCell
                padding="checkbox"
                className="ranking-table-row-small-cell"
            >
                {props.rowData ? (
                    <Checkbox
                        checked={props.rowData.isPlayerShown}
                        style={{ color: props.rowData.color }}
                    />
                ) : (
                    <Skeleton
                        variant="rectangular"
                        width={20}
                        height={20}
                        style={{ margin: 11 }}
                    />
                )}
            </TableCell>
            <TableCell
                align="right"
                className="padding-4 ranking-table-row-small-cell"
            >
                {props.rowData ? props.rowData.rank : <Skeleton variant="text" />}
            </TableCell>
            <TableCell
                align="center"
                className="padding-4 ranking-table-row-small-cell"
            >
                {props.rowData ? (
                    props.rowData.image
                ) : (
                    <Skeleton
                        variant="circular"
                        width={24}
                        height={24}
                    />
                )}
            </TableCell>
            <TableCell
                align="left"
                className="text-ellipsis padding-4"
                style={{ width: 120, maxWidth: 120 }}
            >
                {props.rowData && getPlayerNameWithTrophy(props.rowData.player, props.rowData.trophies)}
            </TableCell>
            <TableCell
                align="right"
                className="padding-4 ranking-table-row-medium-cell"
            >
                {props.rowData ? props.rowData.rankingValue : <Skeleton variant="text" />}
            </TableCell>
            <TableCell
                align="center"
                className="padding-4 ranking-table-row-small-cell"
            >
                {props.rowData ? (
                    props.rowData.movePositionIcon
                ) : (
                    <Skeleton
                        variant="circular"
                        width={24}
                        height={24}
                    />
                )}
            </TableCell>
            <TableCell
                align="right"
                className="padding-4 ranking-table-row-medium-cell"
                style={{ paddingRight: 12 }}
            >
                {props.rowData ? props.rowData.diffElement : <Skeleton variant="text" />}
            </TableCell>
        </TableRow>
    );
};
