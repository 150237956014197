import { Player } from '../../model/player/player';
import { Trophy, TrophyTier } from '../../model/trophy/trophy';

const trophyIcons: Record<TrophyTier, string> = {
    GOLD: '🥇',
    SILVER: '🥈',
    BRONZE: '🥉',
    PARTICIPATION: '🏮',
};

export const getTrophyIcon = (tier: TrophyTier): string => trophyIcons[tier];

export const getPlayerNameWithTrophy = (player: Player, trophies: Trophy[] = []): string => {
    const playerTrophy = trophies.find((trophy) => trophy.playerId === player.id);

    return playerTrophy ? `${player.name} ${getTrophyIcon(playerTrophy.tier)}` : player.name;
};

export const containsTrophyIcon = (name: string): boolean => {
    return Object.values(trophyIcons).some((icon) => name.includes(icon));
};
