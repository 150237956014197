import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import React, { useState } from 'react';
import { PlayerDto } from '../../model/player.dto';
import { GameRanking } from '../../model/ranking/game-ranking';
import { PlayerRanking } from '../../model/ranking/player-ranking';
import { Ranking } from '../../model/ranking/ranking';
import { GameSelector } from '../game-selector/game-selector.comp';
import { getColor } from '../ranking-chart/material-colors';
import { RankingTableRow } from './ranking-table-row.comp';
import { PlayerIcon } from '../player-icon/player-icon.comp';
import { Trophy } from '../../model/trophy/trophy';

export interface RankingTableProps {
    ranking: Ranking;
    players: PlayerDto[];
    shownPlayers: PlayerDto[];
    showPlayer: (player: PlayerDto) => void;
    hidePlayer: (player: PlayerDto) => void;
    highlightPlayer: (player: PlayerDto) => void;
    trophies: Trophy[];
}

export const RankingTable = (props: RankingTableProps) => {
    const [currentGameIndexFromEnd, setCurrentGameIndexFromEnd] = useState(-1);

    const gameRankingsLength = props.ranking?.gameRankings.length;

    const currentGame = props.ranking?.gameRankings[gameRankingsLength + currentGameIndexFromEnd].game;

    return (
        <div
            style={{
                textAlign: 'center',
            }}
        >
            <GameSelector
                currentGame={currentGame}
                players={props.players}
                currentGameIndexFromEnd={currentGameIndexFromEnd}
                gameRankingsLength={gameRankingsLength}
                onSetCurrentGameIndexFromEnd={setCurrentGameIndexFromEnd}
            />

            <Table>
                <TableBody>
                    {!props.ranking
                        ? Array.from(Array(30).keys()).map((index) => <RankingTableRow key={index} />)
                        : renderRanking(props, gameRankingsLength + currentGameIndexFromEnd)}
                </TableBody>
            </Table>
        </div>
    );
};

const getScoreDiffElement = (
    currentGameRanking: GameRanking,
    previousGameRanking: GameRanking,
    playerRanking: PlayerRanking,
    props: RankingTableProps
) => {
    let previousScore = 0;
    if (currentGameRanking !== previousGameRanking) {
        previousScore = previousGameRanking.playerRankings.find(
            (previousPlayerRanking) => previousPlayerRanking.playerId === playerRanking.playerId
        ).rankingValue;
    }
    const scoreDiff = playerRanking.rankingValue - previousScore;
    let diffElement: JSX.Element;
    if (scoreDiff < 0) {
        diffElement = <span style={{ color: 'red' }}>{scoreDiff.toFixed(props.ranking.digis)}</span>;
    }
    if (scoreDiff > 0) {
        diffElement = <span style={{ color: 'green' }}>+{scoreDiff.toFixed(props.ranking.digis)}</span>;
    }
    return diffElement;
};

const getMovePositionIcon = (ranks: Map<number, number>, previousRanks: Map<number, number>, playerId: number) => {
    const rank = ranks.get(playerId);
    const previousRank = previousRanks.get(playerId);
    if (rank > previousRank) {
        return (
            <div className="align-items-center">
                <ArrowDownward data-testid="downward" />
            </div>
        );
    }
    if (rank < previousRank) {
        return (
            <div className="align-items-center">
                <ArrowUpward data-testid="upward" />
            </div>
        );
    }
};

const calculateRanking = (gameRanking: GameRanking, players: PlayerDto[]) => {
    gameRanking.playerRankings.sort((a, b) => b.rankingValue - a.rankingValue);
    const ranks = new Map<number, number>();
    let rank = 1;
    gameRanking.playerRankings.forEach((playerRanking, index) => {
        const player = players.find((player) => player.id === playerRanking.playerId);
        if (!player.active) {
            return;
        }
        ranks.set(playerRanking.playerId, rank);
        if (
            index < gameRanking.playerRankings.length - 1 &&
            playerRanking.rankingValue > gameRanking.playerRankings[index + 1].rankingValue
        ) {
            rank = rank + 1;
        }
    });
    return ranks;
};

const getImageElement = (player: PlayerDto) => (
    <div className="align-items-center">
        <PlayerIcon player={player} />
    </div>
);

const renderRanking = (props: RankingTableProps, currentGameIndex: number) => {
    const currentGameRanking = props.ranking.gameRankings[currentGameIndex];
    let previousGameRanking = currentGameRanking;
    if (currentGameIndex !== 0) {
        previousGameRanking = props.ranking.gameRankings[currentGameIndex - 1];
    }
    currentGameRanking.playerRankings.sort((a, b) => b.rankingValue - a.rankingValue);
    const previousRanks = calculateRanking(previousGameRanking, props.players);
    const ranks = calculateRanking(currentGameRanking, props.players);

    return currentGameRanking.playerRankings.map((playerRanking) => {
        const player = props.players.find((player) => player.id === playerRanking.playerId);
        if (!player.active) {
            return undefined;
        }
        return (
            <RankingTableRow
                key={player.id}
                rowData={{
                    isPlayerShown: props.shownPlayers.map((p) => p.id).includes(playerRanking.playerId),
                    hidePlayer: props.hidePlayer,
                    showPlayer: props.showPlayer,
                    highlightPlayer: props.highlightPlayer,
                    color: getColor(player.id),
                    rank: ranks.get(playerRanking.playerId),
                    image: getImageElement(player),
                    player: player,
                    rankingValue: playerRanking.rankingValue.toFixed(props.ranking.digis),
                    movePositionIcon: getMovePositionIcon(ranks, previousRanks, playerRanking.playerId),
                    diffElement: getScoreDiffElement(currentGameRanking, previousGameRanking, playerRanking, props),
                    trophies: props.trophies,
                }}
            />
        );
    });
};
