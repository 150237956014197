import { useHttpClient } from '../http-client/http-client';
import { useCallback } from 'react';
import { Trophy, TrophyTier } from '../../model/trophy/trophy';

export interface TrophyDto {
    playerId: number;
    tier: TrophyTier;
}

export const useTrophyClient = () => {
    const { get } = useHttpClient();

    const loadTrophies = useCallback(
        async (standorte: string, selectedSaison: string): Promise<Trophy[]> => {
            if (standorte === 'ALL' || standorte === 'ALL_STANDORTE') {
                return [];
            }
            const requestUrl = `/api/trophy?standorte=${standorte}&saison=${selectedSaison}`;
            const response = await get<TrophyDto[]>(requestUrl);
            return (response || []).map((trophy) => ({
                playerId: trophy.playerId,
                tier: trophy.tier,
            }));
        },
        [get]
    );

    return { loadTrophies };
};
